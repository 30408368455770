"use strict";
import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope($scope) {
    let $wrapper = $scope.find('.js-checkout');
    let $continueBtn = $wrapper.find('.js-checkout__continue-btn');
    let $radios = $wrapper.find('.js-checkout__radio');
    let $ajaxForm = $wrapper.find('.js-ajax-form');

    $radios.each(function (index) {
        let $radio = $(this);
        $radio.on('change', function () {
            $continueBtn.removeClass('disabled');
        });
    });

    $ajaxForm.on('success.ajax-form', function (e, data) {
        initModulesInScope();
    });
}