"use strict";
import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope($scope) {
    $scope.find('.js-comparison-area').each(function () {
        let $comparisonArea = $(this);
        let $comparisonAreaWrapper = $comparisonArea.closest('.js-comparison-area__wrapper');
        let $table = $comparisonArea.find('.js-comparison-area__table');
        let $inputs = $comparisonArea.find('.js-comparison-area__input');
        let $selects = $comparisonArea.find('.js-comparison-area__select');
        let $addItemBtn = $comparisonArea.find('.js-comparison-area__add-item');
        let $removeItemBtn = $comparisonArea.find('.js-comparison-area__remove-item');
        let $loadingOverlay = $comparisonArea.find('.js-comparison-area__loading');
        let $differencesToggle = $comparisonArea.find('.js-comparison-area__differences-toggle');
        let $goLeftBtn = $comparisonArea.find('.js-comparison-area__go-left');
        let $addItemMobileBtn = $comparisonArea.find('.js-comparison-area__add-item-mobile');
        let $products = $comparisonArea.find('.js-comparison-area__products');

        calcColWidth($table, $comparisonArea);

        $addItemBtn.on('click', function() {
            let url = $comparisonArea.data('actionItem');
            loadContent(url, $comparisonAreaWrapper, $loadingOverlay, $table);
        });

        $differencesToggle.on('change', function() {
           let url = $comparisonArea.data('actionDifferencesToggle');
              loadContent(url, $table, $loadingOverlay, $table);
        });

        $removeItemBtn.on('click', function() {
            let url = $comparisonArea.data('actionRemoveItem');
            loadContent(url, $comparisonAreaWrapper, $loadingOverlay, $table);
        });

        $inputs.each(function() {
            $(this).on('keyup', function(e) {
                if (e.key === 'Enter') {
                    let $item = $(this).closest('.js-comparison-area__item');
                    let url = $item.data('actionInput');
                    loadContent(url, $comparisonAreaWrapper, $loadingOverlay, $table);
                }
            });
        });

        $selects.each(function() {
            $(this).on('change', function() {
                let $item = $(this).closest('.js-comparison-area__item');
                let url = $item.data('actionSelect');
                loadContent(url, $comparisonAreaWrapper, $loadingOverlay, $table);
            });
        });

        $goLeftBtn.on('click', function() {
            let url = $comparisonArea.data('actionGoLeft');
            $products.addClass('scroll-left');
            $products.on('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function() {
                loadContent(url, $comparisonAreaWrapper, $loadingOverlay, $table);
            });
        });

        $addItemMobileBtn.on('click', function() {
            let url = $comparisonArea.data('actionItem');
            $products.addClass('scroll-right');
            $products.on('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function() {
                loadContent(url, $comparisonAreaWrapper, $loadingOverlay, $table);
            });
        });
    });
}

function calcColWidth($table, $comparisonArea) {
    let $comparisonAreaItems = $comparisonArea.find('.js-comparison-area__item');
    let $tableCells = $table.find('td');
    let columnWidth = $comparisonAreaItems.first().outerWidth();
    $tableCells.each(function() {
        $(this).css('width', columnWidth);
    });
}

function loadContent(url, $target, $loadingOverlay, $table) {
    $loadingOverlay.attr('hidden', false);
    $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        success: function(response) {
            if (response.success) {
                $loadingOverlay.attr('hidden', true);
                $target.html(response.html);
                if($table) {
                    calcColWidth($table, $target);
                }
                initModulesInScope();
            }
        }
    });
}