"use strict";
import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope ($scope) {
    let $container = $scope.find('.js-article-item__container');
    let $result = $container.find('.js-article-item__result');
    let url = $container.data('action');
    let $itemRemoveBtn = $container.find('.js-article-item__remove-btn');
    let $loadingOverlay = $container.find('.js-article-item__loading');

    $itemRemoveBtn.each(function(btn)  {
        let $btn = $(this);
        $btn.on('click', function (e) {
            $loadingOverlay.attr('hidden', false);
            $.ajax(
                {
                    url: url,
                    type: 'GET',
                    success: function (response) {
                        if (response.success) {
                            $loadingOverlay.attr('hidden', true);
                            $result.html(response.html);
                            initModulesInScope();
                        }
                    }
                }
            )
        });
    });
}