"use strict";

export function initInScope($scope) {
    $scope.find('.js-geo-location').on('click', function (evt) {
        evt.preventDefault();
        let $target = $($(this).data('target'));

        navigator.geolocation.getCurrentPosition(function(position) {
            console.log('currentPosition', position.coords.latitude, position.coords.longitude);

            $target.val(position.coords.latitude + ',' + position.coords.longitude).trigger('change');
        });
    });
}